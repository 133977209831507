import React from "react"

import "./style.scss"

import { Document, Page } from "react-pdf"
import { Spinner } from "react-bootstrap"
import { SizeMe } from "react-sizeme"

const TanecznyOffer = () => (
  <div className={"text-center"} >
    <SizeMe>
      {({ size }) => (
    <Document file={require("../../images/oferty/Taneczny weekend zaczarowana kraina_compressed.pdf")} loading={
      <>
        <div>Ładowanie oferty...</div>
        <Spinner animation="grow" variant="brown">
          <span className="sr-only">Loading...</span>
        </Spinner><Spinner animation="grow" variant="brown">
          <span className="sr-only">Loading...</span>
        </Spinner><Spinner animation="grow" variant="brown">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </>
    }>
      <Page pageNumber={1} width={size.width ? size.width : 1} />
      <Page pageNumber={2} width={size.width ? size.width : 1} />
      <Page pageNumber={3} width={size.width ? size.width : 1} />
    </Document>
    )}
  </SizeMe>
  </div>

)

export default TanecznyOffer

