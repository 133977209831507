import React from "react"
import OfertaSezonowaPodwojna, { MoreInfo } from "../OfertaSezonowaPodwojna"
import { Button } from "react-bootstrap"

const WakacjeOferty = () => (
  <>
    <div className={'text-center mt-5'}>
      <Button variant={'brown'} size={"lg"}
              href={"https://docs.google.com/forms/d/1KuJM684KEmo8M6Vbe_F5vcwvGSvnCipLgiRMwg26rkQ"}> WYPEŁNIJ FORMULARZ
        ZGŁOSZENIOWY NA OBÓZ!!!</Button>
      <br/>
      <br/>
      <Button variant={'violet'} size={"lg"}
              href={"https://forms.gle/u7wgzczmkm3XrzSFA"}> WYPEŁNIJ FORMULARZ
        ZGŁOSZENIOWY NA WARSZTATY!!!</Button>
    </div>
    <OfertaSezonowaPodwojna
      title={""}
      offers={[{
        name: "Obóz", src: require("../../images/oferty/Obóz 2024 - ulotka.jpg"),
        description: "Wakacje 2024  14 - 27 lipca",
        subdescription: "",
        title: "OBÓZ TANECZNO - AKROBATYCZNY w Chmielnie 14-27 lipca 2024",
        pdf: require("../../images/oferty/Obóz 2024 - ulotka_compressed.pdf"),
        url: 'https://docs.google.com/forms/d/1KuJM684KEmo8M6Vbe_F5vcwvGSvnCipLgiRMwg26rkQ'

      }
        ,
        {
          name: "Warsztaty", src: require("../../images/oferty/lato 2024 - acro dance art & fun.jpg"),
          description: "Letnie warsztaty taneczno-artystyczne 19-23 sierpnia 2024", subdescription: "",
          // pdf: require("../../images/oferty/lato 2024warsztaty.pdf"),
          pdf: require("../../images/oferty/letnie_warsztaty2024_profildance.pdf"),
          // pdf: require("../../images/oferty/Letnie WARSZTATY DLA DZIECI ORGANIZOWANE PRZEZ PROFIL DANCE.docx_compressed.pdf"),
          // pdf2: require("../../images/oferty/lato 2024 - acro dance art & fun_compressed.pdf"),
          url: 'https://forms.gle/u7wgzczmkm3XrzSFA'
        },
      ]}
      // hideIkonkaDoKlikania={true}
    ></OfertaSezonowaPodwojna>
    <div className={'text-center mt-1 mb-1'}>
      <Button variant={'brown'} size={"lg"}
              href={"https://docs.google.com/forms/d/1KuJM684KEmo8M6Vbe_F5vcwvGSvnCipLgiRMwg26rkQ"}> WYPEŁNIJ FORMULARZ
        ZGŁOSZENIOWY NA OBÓZ!!!</Button>
      <br/>
      <br/>
      <Button variant={'violet'} size={"lg"}
              href={"https://forms.gle/u7wgzczmkm3XrzSFA"}> WYPEŁNIJ FORMULARZ
        ZGŁOSZENIOWY NA WARSZTATY!!!</Button>
    </div>
    {/*<p>Wakacyjny obóz akrobatyczno-taneczny odbędzie się w terminie 14-27 lipca 2024. Szczegóły już wkrótce</p>*/}
    {/*<p>Letnie warsztaty stacjonarne odbędą się w terminie 19-23 sierpnia 2024</p>*/}
    {/*    <OfertaSezonowaPodwojna
      title={"Wakacje 2023"}
      offers={[{
        name: "Taneczne lato 2023", src: require("../../images/oferty/lato_2023_warsztaty.png"),
        description: "Letnie warsztaty taneczno-artystyczne, 21-25 sierpnia 2023", subdescription: "",
        pdf: require("../../images/oferty/lato-2023-acro-dance-art-fun-1.pdf"),
      },
        {
          name: "Obóz 2023", src: require("../../images/oferty/oboz2023.png"),
          description: "Chmielno, 16-29 lipca 2023", subdescription: "",
          pdf: require("../../images/oferty/Obóz-2023.pdf"),
        }
      ]}
      moreInfoFile={require("../../images/oferty/LETNIE-WARSZTATY-DLA-DZIECI-ORGANIZOWANE-PRZEZ-PROFIL-DANCE.pdf")}
    ></OfertaSezonowaPodwojna>
    <MoreInfo title={"UMOWA OBÓZ 23"} moreInfoFile={require("../../images/oferty/umowa-obóz-K-23.pdf")} pages={2}/>
    <MoreInfo title={"Karta kwalifikacyjna uczestnika wypoczynku"} moreInfoFile={require("../../images/oferty/Karta-kwalifikacyjna-uczestnika-wypoczynku.pdf")} pages={2}/>
  */}
  </>
)

export default WakacjeOferty
