import React from "react"
import { Button, Card, Image, Modal, Row } from "react-bootstrap"
import Col from "react-bootstrap/Col"

import "./style.scss"

function OfferModal(props) {
  return (
    <Modal{...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={"modalOffer"}
    >
      <Modal.Header>
        <Image src={props.src} className="modalOfferImage" style={{ objectPosition: `50% ${props.objectPos}%` }}
               rounded/>
      </Modal.Header>
      <Modal.Body>
        <h4 className={"text-center"}>{props.title}</h4>
        <p className={"text-justify"}>
          {/*<pre className={"preprepre"}>*/}
          <p dangerouslySetInnerHTML={{ __html: props.description }}/>
          {/*</pre>*/}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Zamknij</Button>
      </Modal.Footer>
    </Modal>
  )
}

const OfferCard = (props) => {
  const [modalShow, setModalShow] = React.useState(false)

  return (
    <>
      <Col className={"card-col"} lg={4}>
        <Card>
          <Card.Img variant="top" src={props.src}/>
          <Card.Body>
            <Card.Title>{props.title}</Card.Title>
            <Card.Text className={"text-left offershortdesc"}>
              {props.description}
            </Card.Text>
            <Button variant="brown" onClick={() => setModalShow(true)}>Czytaj dalej</Button>
          </Card.Body>
        </Card>
      </Col>
      <OfferModal show={modalShow} onHide={() => setModalShow(false)} {...props} />
    </>

  )
}

const GroupOffer = () => (
  <>
    <Row>
      <OfferCard title={"Modern Jazz"} description={`Modern jazz to styl oparty na technice tańca klasycznego, jazzowego
       i współczesnego. Jego elementami charakterystycznymi są: kontrakcja, reliese, arch, kerf, floorwork, organic movement, extention, rytmiczna 
       izolacja, kontrast dynamiki i miękkości ruchu.<br/><br/>
        Na zajęciach modern jazz pracujemy nad koordynacją, muzykalnością, znajomością podstawowych ćwiczeń
        technicznych, wyrazem scenicznym i pogłębianiem świadomości ciała. Lekcja tańca składa się z rozgrzewki
        ogólnorozwojowej zawierającej ćwiczenia wzmacniające i rozciągające, ćwiczeń technicznych i
        przestrzennych – skoki, obroty, kombinacje wybranych elementów ruchowych, oraz nauki i ćwiczenia
        układów choreograficznych. Efekty całorocznej pracy można zobaczyć na naszym spektaklu
        końcoworocznym oraz na pokazach i turniejach tanecznych <br/><br/>
        <b>Grupy:</b> Profil Prim, Zespół Profil<br/>
        <b>Strój:</b> wygodny strój nieograniczający ruchów, bezpalcówki lub skarpetki, związane włosy`}
                 src={require("../../images/kwadraty - zajęcia/profil-588_optimized.jpg")}
                 objectPos={30}
      />
      <OfferCard title={"Jazz"} description={`Jazz to technika tańca, którą charakteryzuje dynamiczny, precyzyjny ruch. Jest to styl stworzony do
wystawiania na scenie i dlatego układy jazzowe często angażują mimikę twarzy i zawierają elementy
popisowe – tricki taneczne, obroty, wymachy, sztuczki gimnastyczne. Istnieje wiele odmian jazzu np. lirycal
jazz, broadway jazz, modern jazz, funky jazz. <br/><br/>
Na zajęciach jazzowych pracujemy nad koordynacją, muzykalnością, znajomością podstawowych ćwiczeń
technicznych, gibkością i wyrazem scenicznym. Lekcja tańca składa się z rozgrzewki ogólnorozwojowej
zawierającej ćwiczenia wzmacniające i rozciągające, ćwiczeń technicznych i przestrzennych – skoki, obroty,
wymachy oraz nauki i ćwiczenia układów choreograficznych. Efekty całorocznej pracy można zobaczyć na
naszym spektaklu końcoworocznym oraz na pokazach i turniejach tanecznych <br/><br/>
<b>Grupy:</b> Profil Mini <br/>
<b>Strój:</b> wygodny, przylegający do ciała strój nieograniczający ruchów, bezpalcówki/boso, związane włosy`}
                 src={require("../../images/kwadraty - zajęcia/profil-595_optimized.jpg")}
                 objectPos={15}
      />
      <OfferCard title={"AkroGim"} description={`AkroGim to zajęcia akrobatyczno-gimnastyczne, podczas których uczymy się skoków akrobatycznych na
ścieżce, statycznych elementów gimnastycznych, łączenia elementów przestrzennych, krótkich układów
akrobatycznych. Nasza sala wyposażona jest w profesjonalny sprzęt: ścieżkę aerodynamiczną AirTrack,
trampolinę sportową ukośną, akrobatyczną ścieżkę filcową, odskocznię, materace asekuracyjne oraz
twarde, drabinki sportowe, piłki, skakanki, skrzynię i kozioł. Na miejscu znajduje się szatnia. <br/><br/>
Trening składa się z rozgrzewki ogólnorozwojowej, skupionej na wzmacnianiu mięśni, rozwijaniu gibkości,
skoczności i zwinności, oraz części głównej – nauki i treningu wybranych elementów, m.in.: stanie na
rękach, mostek, przewrót w przód i tył, lotka, szpiczaga, leżenie przerzutne, przerzut bokiem, rundak,
przerzut w przód, przejście w przód i tył, salto kuczne w przód i tył, salto proste, salto machowe bokiem i
przodem, fiflak, wychwyt z głowy i z karku. Coraz trudniejsze i samodzielne ćwiczenia wprowadzane są
stopniowo, z dostosowaniem do poziomu i zawsze poprzedzone są odpowiednimi ćwiczeniami
wprawkowymi oraz ćwiczeniem z asekuracją trenera. Zajęcia prowadzą doświadczeni trenerzy (co najmniej
2 trenerów na sali), co umożliwia indywidualne podejście do uczestników, podział na mniejsze podgrupy z
uwzględnieniem poziomu umiejętności, a także zwiększa bezpieczeństwo.<br/><br/>
<b>Grupy:</b> AkroGim 1, AkroGim 2, Akrogim 3 <br/>
<b>Zalecany strój:</b> kostium akrobatyczny lub strój sportowy przylegający do ciała – niezbędny do
bezpiecznej asekuracji, bose stopy lub skarpetki antypoślizgowe`}
                 src={require("../../images/kwadraty - zajęcia/_KOG7261_optimized.jpg")}
                 objectPos={70}
      />
    </Row>
    <Row className={"justify-content-md-center"}>
      <OfferCard title={"Flexibility&Control"} description={`Flexibility&Control to specjalistyczne zajęcia wspomagające dla tancerzy na poziomie
średniozaawansowanym i zaawansowanym. Podczas treningów skupiamy się na rozwijaniu umiejętności
technicznych, poszerzaniu zakresów ruchu, wzmacnianiu ciała, zwiększaniu świadomości i kontroli w tańcu
i ćwiczeniach fizycznych. Na zajęciach korzystamy z gum sportowych i elastycznych pasków do rozciągania
i oporowania, kostek do jogi, drabinek, mat fitnessowych i materaców cienkich oraz ciężarków. <br/><br/>
Zajęcia składają się z rozgrzewki przygotowującej ciało do wysokiego wysiłku fizycznego oraz części
głównej nakierowanej na rozwijane konkretnych elementów technicznych (ze zwróceniem szczególnej
uwagi na jakość wykonania ćwiczeń), wzmacnianie wybranych partii mięśniowych, głęboki stretching oraz
trening i naukę tricków tanecznych i elementów popisowych. Pracujemy przede wszystkim nad
wykręceniem, dynamiką i wybiciem, prawidłową pracą stóp, utrzymywaniem równowagi, szpagatami i
nadszpagatami, wygięciem w tył, pozycjami passe, arabesque, releve, tilt, leg hold, piruetami i obrotami
oraz skokami przestrzennymi i w miejscu. <br/><br/>
<b>Grupy:</b> Flexibility&Control<br/>
<b>Strój:</b> wygodny strój nieograniczający ruchów, bezpalcówki lub skarpetki, związane włosy`}
                 src={require("../../images/kwadraty - zajęcia/46_optimized.jpg")}
                 objectPos={92}
      />
      <OfferCard title={"Baby Dance"} description={`Baby Dance to ogólnorozwojowe zajęcia taneczne zawierające elementy techniki tańca jazzowego i klasycznego. Są one
przeznaczone dla najmłodszych, czyli dla dzieci w wieku od 3 do 6 lat. Nasze zajęcia nastawione są przede
wszystkim na dobrą zabawę i roztańczenie naszych przyszłych artystów.<br/><br/>
Na zajęciach pracujemy nad gracją i estetyką ruchu, muzykalnością, koordynacją, gibkością i skocznością.
Podczas lekcji Baby Dance dzieci uczą się także przystosowywania się do zasad i schematu zajęć,
zapamiętywania krótkich sekwencji choreograficznych, nazewnictwa i wykonywania podstawowych
ćwiczeń technicznych. Wszystkie ćwiczenia – rozgrzewkowe, rytmizujące, rozciągające i techniczne – są
wprowadzane w formie zabawowej, przyjaznej dzieciom i zachęcającej do ćwiczenia. Efekty całorocznej pracy można zobaczyć na zajęciach otwartych dla rodziców (na
koniec semestru) i na naszym spektaklu końcoworocznym.<br/><br/>
<b>Zalecany strój:</b> wygodny strój nieograniczający ruchów, bezpalcówki, baletki lub skarpetki, związane włosy.`}
                 src={require("../../images/do_100/baby2.jpg")}
                 objectPos={78}
      />
      <OfferCard title={"Pierwszy Taniec"}
                 description={`Lekcje pierwszego tańca to indywidualne zajęcia dla par, podczas których instruktor przygotowuje Młodą Parę do ważnego momentu, jakim jest pierwszy taniec weselny. Pomagamy dobrać utwór i styl tańca (walc wiedeński, angielski lub dowolny styl tańca towarzyskiego) oraz tworzymy specjalnie dedykowaną dla pary choreografię. Poziom zaawansowania oraz tempo nauki choreografii zawsze dostosowujemy do indywidualnych predyspozycji i życzeń. Wiemy, że dla niektórych pierwszy taniec jest stresującym wyzwaniem, dlatego nasi instruktorzy stawiają na przyjazną i wspierającą atmosferę pracy i przede wszystkim indywidualne podejście (również z dostosowaniem godzin i ilości lekcji. Serdecznie zapraszamy do kontatu.`}
                 src={require("../../images/do_100/DSC_0055-1_Easy-Resize.com (1).jpg")}
                 objectPos={78}
      />
    </Row>

    <Row className={"justify-content-md-center"}>
      <OfferCard title={"Taneczny Wieczór Panieński"} description={`
Taneczny wieczór panieński to świetna alternatywa na spędzenie tego czasu w wyjątkowy, inny
sposób. To pomysł zarówno dla osób, które tańczą, ale również dla tych, które są zupełnie
początkujące. Na takim wieczorze panieńskim przyszła Panna Młoda, ale i pozostałe uczestniczki,
mogą poczuć się świetnie i wyjątkowo. Nasi instruktorzy zagwarantują specjalnie na tą okazję
przystrojoną salę taneczną, świetną atmosferę a co najważniejsze <b>niezapomniane wrażenia</b> przed
najważniejszym dniem dla Panny Młodej.`}
                 src={require("../../images/do_100/Wieczór panieński 1_Easy-Resize.com.jpg")}
      />
      <OfferCard title={"Lekcje indywidualne"} description={`
Indywidualne lekcje tańca to niesamowicie efektywna forma zajęć, podczas której instruktor poświęca uczniowi 100% swojej uwagi i w pełni dopasowuje się do jego potrzeb. W naszej szkole udzielamy lekcji indywidualnych zarówno dla osób, które nie uczęszczają na zajęcia grupowe, preferując kameralną atmosferę i indywidualne podejście instruktora (dostosowanie tempa i materiału do predyspozycji uczestnika), jak i dla tych, którzy należą do naszych grup zajęciowych i chcą dodatkowo popracować nad techniką tańca, świadomością ciała, jakością ruchu i wykonu choreografii. Z doświadczenia wiemy, że takie zajęcia są bardzo owocną formą nauki i przynoszą wspaniałe efekty w krótkim czasie.
Zajęcia indywidualne, to także jedyna okazja do przygotowania i wypracowania pod okiem naszych instruktorów prezentacji solo, duet, trio. Podczas procesu twórczego, wspólnie z uczestnikami, dobieramy odpowiednią muzykę i charakter choreografii, zgodną z ich preferencjami i naturalnymi predyspozycjami. Pracę nad solówkami, duetami i tercetami traktujemy jako okazję do wydobycia z tancerzy maksimum ich możliwości. Staramy się zatem, aby nasze układy pokazywały ich najmocniejsze strony, ale także, by były dla nich wyzwaniem i służyły ich tanecznemu rozwojowi. Wszystkim prezentacjom oferujemy możliwość wystąpienia na naszym spektaklu końcoworocznym a dodatkowo wybranym - reprezentowanie naszej szkoły tańca na turniejach i zawodach tanecznych.
`}
                 src={require("../../images/oferty/profil_indywidualne.jpg")}
      />
      <OfferCard title={"Broadway Musical"} description={`
    Broadway Musical są to zajęcia w dwóch grupach wiekowych: Musical Dance 6-10 lat <s>oraz Broadway
    for Ladies 30 +</s>(grupa zawieszona). Jest to mieszanka różnych stylów tanecznych, aktorstwa i dobrej zabawy. Zajęcia są
    niezwykle ekspresyjne, kształtują umiejętności artystycznego wyrażania siebie, pamięci ruchowej,
    sceniczności i koordynacji. Jeśli jesteś miłośniczką Broadway&#39;u, to nasze zajęcia &quot;Broadway Musical&quot;
    są zdecydowanie dla Ciebie. Niezależnie od poziomu zaawansowania nasz kurs pomoże Ci rozwijać
    się ruchowo i czerpać radość z tańca.<br/><br/>
    <b>Grupy</b>: Musical Dance 6-10 lat<s>, Broadway for Ladies 30+</s><br/><br/>
    <b>Strój</b>: wygodny, przylegający do ciała strój nieograniczający ruchów, buty bądź skarpetki, związane włosy`}
                 src={require("../../images/do_100/Broadway Musical_Easy-Resize.com.jpg")}
      />
    </Row>
  </>
)

export default GroupOffer

