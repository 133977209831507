import React from "react"
import { Button, Image } from "react-bootstrap"

import "./style.scss"
import { Link } from "gatsby"
import { TiArrowRightOutline } from "react-icons/ti"


const IndividualOffer = () => (
  <div className={"text-justify"}>
    <p><b>Indywidualne lekcje tańca</b> to niesamowicie efektywna forma zajęć, podczas której instruktor poświęca
      uczniowi 100% swojej uwagi i w pełni dopasowuje się do
      jego potrzeb. W naszej szkole udzielamy lekcji indywidualnych zarówno dla osób, które nie uczęszczają na zajęcia
      grupowe, preferując kameralną atmosferę i
      indywidualne podejście instruktora (dostosowanie tempa i materiału do predyspozycji uczestnika), jak i dla tych,
      którzy należą do naszych grup zajęciowych i chcą
      dodatkowo popracować nad techniką tańca, świadomością ciała, jakością ruchu i wykonu choreografii. Z doświadczenia
      wiemy, że takie zajęcia są bardzo owocną
      formą nauki i przynoszą wspaniałe efekty w krótkim czasie.</p>
    <p>
      Zajęcia indywidualne, to także jedyna okazja do przygotowania i wypracowania pod okiem naszych instruktorów <b>prezentacji
      solo, duet, trio</b>. Podczas procesu
      twórczego, wspólnie z uczestnikami, dobieramy odpowiednią muzykę i charakter choreografii, zgodną z ich
      preferencjami i naturalnymi predyspozycjami. Pracę
      nad solówkami, duetami i tercetami traktujemy jako okazję do wydobycia z tancerzy maksimum ich możliwości. Staramy
      się zatem, aby nasze układy pokazywały
      ich najmocniejsze strony, ale także, by były dla nich wyzwaniem i służyły ich tanecznemu rozwojowi. Wszystkim
      prezentacjom oferujemy możliwość wystąpienia na
      naszym spektaklu końcoworocznym a dodatkowo wybranym - reprezentowanie naszej szkoły tańca na turniejach i
      zawodach tanecznych.</p>
    <Image src={require("../../images/oferty/BlackMinimalistMarketingProposal(2)_optimized.png")} width={"100%"}
           rounded/>
    <p className={"pt-3"}>Aby umówić się na lekcje indywidualne skontaktuj się z nami! Zajęcia mogą odbywać się
      regularnie lub nieregularnie (ustalane bezpośrednio z instruktorem).
      Zapraszamy do zapoznania się z naszym cennikiem oraz ofertą pakietów solo i duety.</p>
    <div className={"pt-1 text-center"}>
      <Link to="/cennik" className="link-no-style">
        <Button className="cennikbutton" variant={"brown"} size={"lg"}>Zapoznaj się z cennikiem
          zajęć{" "}<TiArrowRightOutline size={"1.2em"}/></Button>
      </Link>
    </div>
  </div>

)

export default IndividualOffer

