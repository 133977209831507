import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ResponsiveSectionsNavigation from "../components/responsivesectionsnavigation"
import GroupOffer from "../components/offersections/groupoffer"
import IndividualOffer from "../components/offersections/individualoffer"
import CampOffer from "../components/offersections/campoffer"
import WorkshopsOffer from "../components/offersections/workshopsoffer"
import IntensivesOffer from "../components/offersections/intensivesoffer"
import FerieOffer from "../components/offersections/ferieoffer"
import FerieOferty from "../components/ferieOferty"
import WakacjeOferty from "../components/wakacjeOferty"
import TanecznyOffer from "../components/offersections/tanecznyweekend"

const SecondPage = () => {
  const [currentOffer, setCurrentOffer] = useState('Zajęcia grupowe')
  return (
  <Layout pageInfo={{ pageName: "oferta" }}>
    <SEO title="Oferta"/>
    <h1 className={"text-center"}>Oferta - {currentOffer}</h1>
    <ResponsiveSectionsNavigation
      currentLocationChanged={(v)=>setCurrentOffer(v[1])}
      defaultActiveKey={"zajecia"}
                                  navigs={[
                                    ["zajecia", "Oferta zajęć", <GroupOffer/>],
                                    ["indywidualne", "Solo Duo Trio", <IndividualOffer/>],
                                    // ["obozy", "Obozy treningowe", <CampOffer/>],
                                    ["warsztaty", "Sunday workshops", <WorkshopsOffer/>],
                                    ["intensives", "Dance Intensives", <IntensivesOffer/>],
                                    ["weekend", "Taneczny weekend", <TanecznyOffer/>],
                                    ["ferie", "Ferie dla dzieci", <FerieOferty/>],
                                    ["wakacje", "Wakacje dla dzieci", <WakacjeOferty/>],
                                  ]}
    />


  </Layout>
)}

export default SecondPage
